@font-face {
  font-family: rbold;
  src: url('../public/font/Roboto-Bold.ttf')
}

@font-face {
  font-family: rregular;
  src: url('../public/font/Roboto-Regular.ttf')
}

@font-face {
  font-family: rmedium;
  src: url('../public/font/Roboto-Medium.ttf')
}

@font-face {
  font-family: rlight;
  src: url('../public/font/Roboto-Light.ttf')
}

@font-face {
  font-family: msemi;
  src: url('../public/font/Mulish-SemiBold.ttf')
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

#root {
  height: 100dvh;
  overflow: hidden;
}

/* common */
.horizontal-bar {
  border-top: .1rem solid #7A7D8B;
}

.vertical-bar {
  border-left: .1rem solid #7A7D8B;
}

.cursor-pointer {
  cursor: pointer;
}

button {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  transition: transform .7s ease-in-out;
}

/* end */

.css-18nc3u2 .MuiOutlinedInput-root {
  padding: 5px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 9px !important;
  top: 8px !important;
}

.right-layout {
  border-top-left-radius: 5rem;
  background-color: #F7F8FC;
  border-top: 1px solid rgb(0, 0, 0, 20%);
  border-left: 1px solid rgb(0, 0, 0, 20%);
}

/* auth */
#auth {
  background-color: pink;
  display: flex;
}

#auth .auth-left {
  background-color: rgb(179, 29, 54);
}

#auth .auth-left img {
  object-fit: cover;
  width: 100%;
  height: 100dvh;
}

#auth .auth-right {
  background-color: #0E0035;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#auth .auth-right .main-logo {
  width: 350px;
  height: 10rem;
}

#auth .sub1-auth-right {
  width: 90%;
}

.main-form {
  background-color: rgb(178, 178, 178, 7%);
  border-radius: 5.6rem;
  height: auto;
  padding: 30px;
}

#auth .subsub1-sub1-auth-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#auth .subsub1-sub1-auth-right h3 {
  font-size: 4rem;
  color: white;
  font-weight: 600;
  margin-bottom: 3rem;
}

#auth .input-container {
  width: 100%;
  background-color: rgb(255, 255, 255, 0%);
  margin-bottom: 3rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

#auth .input-container img {
  margin-right: 1rem;
  width: 50px;
}

#auth .input-container input {
  width: 300px;
  font-size: 2rem;
  background-color: rgb(255, 255, 255, 0%);
  color: white;
  font-family: rlight;
  text-align: start;
  border: 0;
  outline: none;
  border-bottom: .1rem solid white;

}

#auth .input-container input::placeholder {
  color: white;
}

#auth .input-container input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  background-color: rgb(255, 255, 255, 0%);
  border-radius: 5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .4rem;
  border: .1rem solid white;
}

#auth .input-container input[type="checkbox"]:after {
  font-family: "Font Awesome 6 Free";
  content: "\f00c";
  font-size: 1.3rem;
  color: #0E0035;
  display: none;
  font-weight: 1000;
  padding-top: .3rem;
}

.suggestion {
  top: 3.2rem;
  color: black;
  z-index: 3;
  background-color: #F7F8FC;
  font-size: 1.6rem;
  max-height: 15rem;
}

.suggestion p {
  color: black;
  font-size: 1.6rem;
  font-weight: inherit;
}

#auth .input-container input[type="checkbox"]:checked {
  background-color: white;
}

#auth .input-container input[type="checkbox"]:checked::after {
  display: block;
}

#auth .subsub1-sub1-auth-right .error {
  font-size: 1.5rem;
  color: red;
  text-align: center;
  font-weight: 200;
}

#auth .subsub1-sub1-auth-right .have-account,
#auth .subsub1-sub1-auth-right .forgot-password,
#auth .subsub1-sub1-auth-right .reset {
  font-size: 1.5rem;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 200;
  cursor: pointer;
}

#auth .subsub1-sub1-auth-right button {
  min-width: 15rem;
  font-size: 2rem;
  background-color: #FD2A22;
  color: white;
  text-align: center;
  border: 0;
  border-radius: 30px;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-left: 0;
  font-family: rmedium;
}

#auth .subsub2-sub1-auth-right {
  display: flex;
  justify-content: center;
}

.imgloading {
  margin-top: 1% !important;
  width: 2rem !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 4rem;
  font-size: 1.6rem !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.techwelt-confirm .ant-modal-content {
  background-color: #364153;
}

.techwelt-confirm .ant-modal-confirm-content {
  color: #fff !important;
  text-align: center !important;
  font-size: 2rem !important;
  line-height: 2.4rem !important;
  font-family: rregular;
  margin-bottom: 2rem;
  max-width: 100% !important;
}

.techwelt-confirm .ant-modal-confirm-btns {
  text-align: center;
}

.techwelt-confirm .btn-yes,
.techwelt-confirm .btn-no {
  width: 10rem;
  height: 2.5rem;
  line-height: 1.5rem;
  color: #fff;
  border: .1rem solid #fff;
}

.techwelt-confirm .btn-yes {
  background-color: #D01400;
}

.techwelt-confirm .btn-no {
  background-color: #28A745;
  margin-inline-start: 7rem !important;
}


@media(max-width:1390px) {
  html {
    font-size: 9px;
  }
}

@media(max-width:1256px) {
  html {
    font-size: 8px;
  }

  #auth .main-form {
    height: calc(100dvh - 36rem);
  }
}

@media(max-width:767px) {
  .app {
    position: relative;
    height: 100dvh;
  }

  .right-layout {
    border: none;
    background-color: white;
    overflow-y: hidden;
    border-radius: 0;
  }

  #auth {
    flex-direction: column;
    height: 100dvh;
  }

  #auth .auth-left img {
    height: 40dvh;
  }

  #auth .auth-right {
    position: absolute;
    bottom: 0;
    min-height: 60dvh;
  }

  #auth .auth-right .main-logo {
    width: 350px;
    height: 6rem;
    margin-bottom: 1rem !important;
  }

  #auth .main-form {
    height: max-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #auth .subsub1-sub1-auth-right h3 {
    font-size: 3rem;
    margin-bottom: 2rem !important;
  }

  #auth .input-container input {
    font-size: 1.5rem;
  }

  #auth .subsub2-sub1-auth-right {
    margin-top: 1rem !important;
  }

  #auth .subsub1-sub1-auth-right button {
    font-size: 1.5rem;
  }

  #auth .subsub1-sub1-auth-right .have-account {
    margin-bottom: 1rem;
  }

  #auth .input-container {
    margin-bottom: 2rem !important;
    width: 70%;
  }

  .mobile-title {
    box-shadow: 0rem .4rem .5rem rgb(167, 165, 165);
  }
}


.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.sw-pinit-button,
div.sw-pinit a.sw-pinit-button:hover:before {
  display: none;
}

.ant-checkbox .ant-checkbox-inner:after {
  border-color: #f00 !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #7A7D8B !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #7A7D8B !important;
  background-color: #fff !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #f00 !important;
  background-color: #fff !important;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #f00 !important;
  width: 24px !important;
  height: 24px !important;
  margin-block-start: -12px !important;
  margin-inline-start: -12px !important;
}

.ant-radio-wrapper .ant-radio-checked + span {
  color: #f00;
}


.ant-select .ant-select-selection-placeholder {
  color: grey;
  font-size: 1.6rem;
  line-height: inherit !important;
}


.ant-input::placeholder {
  color: grey;
}

/* global scroll bar settings */
.scrollbar-hide {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0.4rem;
  margin-left: 1rem;
}

::-webkit-scrollbar-track {
  background-color: #F5F5F5;
  border: .1rem solid #374957;
  border-radius: .5rem;
  _border-top-left-radius: .5rem;
  _border-top-right-radius: .5rem;
  _border-bottom: 0rem;
}

::-webkit-scrollbar-thumb {
  background-color: #374957;
  border-radius: 1rem;
}

/* global datepicker settings */

.react-datepicker {
  padding: 2rem 2rem 1rem 2rem;
  box-shadow: 2px 16px 19px 0px #00000017, 0px 4px 4px 0px #00000040;
  border-color: #7A7D8B66;
  border-radius: .8rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__close-icon::after {
  background-color: #cccccc;
}

.react-datepicker__input-container>input {
  outline: none;
  border-radius: .5rem;
  border: .1rem solid #DFE0EB;
  text-align: center;
  width: 25rem;
  max-width: 100%;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding: 0;
  margin-bottom: 1.6rem;
}

.react-datepicker__current-month {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #4A5660;
}

.react-datepicker__navigation--previous {
  top: 2rem;
  left: 2rem;
}

.react-datepicker__navigation--next {
  top: 2rem;
  right: 2rem;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-name {
  color: #c3c4cf !important;
  font-family: rregular;
  font-size: 1.2rem;
  width: 3rem;
  height: 2rem;
  margin: .2rem;
}

.react-datepicker__day {
  width: 3rem;
  height: 3rem;
  font-family: rregular;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  border: 1px solid transparent;
  border-radius: 1.5rem;
  margin: .2rem;
  color: #4A5660;
}

.react-datepicker__day:hover {
  border-radius: 1.5rem;
}

.react-datepicker__day--today {
  border-color: #F04D23;
  border-radius: 30px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: white;
}

.react-datepicker__day--selected {
  color: white;
  background-color: #F04D23;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  color: #aaa;
}
.react-datepicker__time-container {
  display: none !important;
}
.react-datepicker__input-time-container {
  margin: .5rem 0;
  text-align: center;
}

.react-datepicker-time__input {
  border: .1rem solid #7A7D8B;
  border-radius: .5rem;
}

.react-datepicker-time__caption {
  display: none !important;
}
.react-datepicker-time__input>input {
  color: #4A5660;
  font-size: 1.4rem;
  font-weight: 600;
  outline: none;
  border: none;
  text-align: center;
}
.react-datepicker__close-icon {
  display: none !important;
}
.date-picker-input {
  position: relative;
}
.date-picker-input > img {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: .4rem;
  right: 1rem;
}

.react-datepicker__children-container {
  width: 100%;
}

.react-datepicker__children-container > button {
  background-color: #364153;
  border-radius: 1rem;
  color: white;
  font-size: 1.4rem;
  width: 12rem;
  margin-top: 1rem;
}