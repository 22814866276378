
.sub1-telemetry-setting-div1{
  width: 26.6rem;
  background-color: #D01400;
  border: .1rem solid white;
  box-shadow: -0.2rem 0.2rem 0.2rem grey;
  border-radius: .5rem;
  font-weight: 500;
}

.sub1-telemetry-setting-div1 p {
  font-size: 1.2rem;
}

.sub1-telemetry-setting-div2 {
  width: 22rem;
  background-color: #364153;
  border: .1rem solid white;
  box-shadow: -0.3rem 0.3rem 0.3rem grey;
  border-radius: .5rem;
  font-family: rbold;
  font-size: 1.3rem;
}

.btn-add-widget {
  width: 25rem;
  background-color: #28A745;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
  font-family: rbold;
  font-size: 1.3rem;
  color: white;
  padding: .7rem 2rem;
  box-shadow: 2px 0.3rem 1rem 0px grey;
}
.parameters-list {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: .5rem 1rem;
  margin-top: .5rem;
  margin-bottom: 0;
  width: 40rem;
}
.parameters-list a {
  font-size: 1.2rem;
  line-height: 2.5rem;
  margin-bottom: 0;
  cursor: pointer;
}
.parameters-list a.added {
  background-color:  lightgrey;
}

.telemetry-items-wrapper {
  border: 0.1rem solid #dfe0eb;
  border-radius: 2rem;
  background-color: #F3F4F7;
  padding-right: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.telemetry-items {
  height: calc(100vh - 300px);
}
.telemetry-items .grid-item {
  padding: 0 1rem;
  padding-top: 1.5rem;
}

.telemetry-item {
  _width: 22%;
  min-width: 20rem;
  height: 5.5rem;
  background-color: #fff;
  border: 0.1px solid #005EEC;
  border-radius: .5rem;
  padding: 0 3rem;
  font-size: 1.2rem;
  box-shadow: 0px 1.1px 0.8rem -1px grey;
  font-family: rregular;
  user-select: none !important;
}
.telemetry-item.disabled {
  background-color: #dedede;
}
.telemetry-item.high {
  background-color: #C8FFC8;
}
.telemetry-item.low {
  background-color: #FFC8C8;
}
.telemetry-item span.update-date {
  position: absolute;
  top: .4rem;
  right: .6rem;
  color: #7A7D8B;
  font-size: .8rem;
}
.telemetry-item .item-action {
  position: absolute;
  top: 1.8rem;
  right: 1rem;
  /* display: none; */
}

.telemetry-item:hover .item-action {
  display: block;
}
.telemetry-item .dropdown-menu {
  background-color: #D8D7DA;
  transform: translate(15px, 21px) !important;
  cursor: pointer;
}
