.geofence-top-panel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: .5rem 1rem;
  z-index: 3;
}

.sub1-geofence-div1 {
  border-radius: 2rem;
  border: .1rem solid #EBECF0;
}

.geofence-top-panel-wrapper label {
  font-size: 1.6rem;
  color:#D01400;
  font-family: rmedium;
}
.geofence-top-panel-wrapper .ant-btn {
  font-size: 1.6rem;
  color:#7A7D8B;
  font-family: rmedium;
  width: 23rem;
  height: 4rem;
}

.geofence-top-panel-wrapper .divider {
  width: .1rem;
  height: 8rem;
  background-color: #DFE0EB;
}

.geofence-dropdown-menu {
  border-radius: .7rem;
  box-shadow: -.1rem .1rem .5rem grey;
  border: .1rem solid #d9d9d9;
  font-size: 1.6rem;
}
.geofence-dropdown-menu > :hover {
  background-color: #dfe0eb;
}

.geofence-drawings-panel {
  top: 15rem;
  z-index: 2;
}
.geofence-drawings-panel > div {
  width: 6rem;
  height: 6rem;
  background-color: white;
  border-radius: .6rem;
  border: .1rem solid #7A7D8B;
  cursor: pointer;
}
.geofence-drawings-panel > div.selected {
  border-color: #FF0000;
}
.geofence-drawings-panel > div img {
  width: 5.8rem;
  height: 5.8rem;
}

@media(max-width:767px) {
  .geofence-top-panel-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }

  .geofence-drawings-panel {
    top: 50%;
    transform: translateY(-50%);
  }
}