.switch-container {
  font-size: 1.2rem;
  font-family: rregular;
}

.switch-slider-container {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.1rem;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(166, 180, 200, 25%);
  transition: 0.4s;
  border-radius: 5rem;
}

.switch-slider::before {
  position: absolute;
  content: "";
  border: .1rem solid #EEF0F5;
  height: 1.6rem;
  width: 1.6rem;
  left: -0.1rem;
  bottom: -0.3rem;
  background-color: #FAFBFB;
  transition: 0.4s;
  border-radius: 5rem;
}

input:checked + .switch-slider {
  background-color: #FD2A22;
  border-radius: 5rem;
  box-shadow: 0rem 0rem 1rem #FD2A22;
}

input:checked + .switch-slider::before {
  transform: translateX(1.5rem);
}

.switch-slider-container input {
  opacity: 0;
  width: 0;
  height: 0;
}