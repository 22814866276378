.text-input-container {
  border: .1rem solid #898A8D;
  border-radius: .5rem;
  max-width: 100%;
  width: 29rem;
  padding: 0 1rem;
  font-size: 1.2rem;
  font-family: rregular;
  background-color: #fff;;
}
.text-input-container.highlight {
  border-color: #005EEC;
}

.text-input-container input {
  outline: none;
  background: transparent;
}

.text-input-container select {
  outline: none;
  background: transparent;
}