.edit-user-main p {
    font-size: 2rem;
    padding-left: 5rem;
    padding-top: 2rem;
    color:#D01400;
    font-weight: 700;
}

.edit-user-main .normal-input {
    width: 45rem;
    border: .1rem solid #DFE0EB;
    height: 4rem;
    padding-left: 1rem;
    outline: none;
    border-radius: .8rem;
}
.edit-user-main .input-item {
    max-width: 100%;
    width: 45rem;
}

.edit-user-main .suggestion {
    top: 7rem;
}

.edit-user-div1 {
    border: .1rem solid #DFE0EB;
    border-radius: 2rem;
    background-color: rgb(223,224,235,12%);
    font-size: 1.6rem;
}

.person-edit-user {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    padding: .5rem;
    border: .1rem solid #b8b8bd;
}

.sub2-subsub1-sub1-edit-user-div1 :nth-child(3) { 
    bottom: 1.25rem;
    right: 2rem;
}

.sub2-subsub1-sub1-edit-user-div1 input[type="checkbox"] {
    -webkit-appearance:none ;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 5rem;
    cursor: pointer;
}

.sub2-subsub1-sub1-edit-user-div1 input[type="checkbox"]:after {
    font-family: "Font Awesome 6 Free";
    content: "\f00c";
    font-size: 1rem;
    color:white;
    display: none;
    font-weight: 900;
}

.sub2-subsub1-sub1-edit-user-div1 input[type="checkbox"]:checked {
    background-color: #1E6B97;
}

.sub2-subsub1-sub1-edit-user-div1 input[type="checkbox"]:checked::after{
    display: block;
}

.subsub5-sub1-edit-user-div1 button {
    width: 24rem;
    height: 4rem;
    background-color: #AD0606;
    color:white;
    font-size: 1.6rem;
    border-radius: 5rem;
    border: 0rem;
    margin-top: 3rem;
}
.subsub5-sub1-edit-user-div1 button:hover {
    background-color: #3751ff;
}
.subsub5-sub1-edit-user-div1 button:active {
    background-color: #640404;
}

@media(max-width:768px) {
    .edit-user-main {
        padding: 2rem 1rem;
    }

    .edit-user-main p {
        text-align: center;
        font-size: 3rem;
        padding-left: 0;
    }

    .edit-user-div1 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 1rem !important;
    }

    .edit-input-container {
        width: 100%;
        flex-direction: column;
        margin-bottom: 1rem !important;
    }
    
    .edit-input-container label {
        font-weight: 500;
        font-size: 2rem;
    }

    .edit-user-main .normal-input {
        width: 100%;
    }
}
