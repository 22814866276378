.map-container {
  height: calc(100dvh - 11rem);
}

.map-container .vehicle-search-input {
  top: 1rem;
  font-size: 1.6rem;
  color: #D01400;
  text-align: center;
  width: 28rem;
  z-index: 3;
}
.map-container .vehicle-search-input.mobile {
  top: auto;
  bottom: 2rem;
}
.map-container .vehicle-search-input .ant-select-selector {
  border-color: #D01400;
}
.map-container .vehicle-search-input .ant-select-selection-search-input,
.map-container .vehicle-search-input .ant-select-selection-item {
  text-align: center !important;
  color: #D01400 !important;
}


@media(max-width:767px) {
  .map-container {
    height: 100dvh;
  }
}
