.phone-number-input {
  background-color: #fff;
  border: .1rem solid #DFE0EB;
  height: 4rem;
  padding-left: 2rem;
  border-radius: .8rem;
}
.phone-number-input .PhoneInputInput {
  width: 5rem;
  border: 0;
  outline: none;
}
.phone-number-input .ant-input {
  height: 3rem;
  flex-grow: 1;
  border-left: .1rem solid #7A7D8B;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: inherit;
  margin-top: .4rem;
}