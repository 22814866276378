
.assing-vehicle-modal .list-header {
  height: 4.4rem;
  background-color: #364153;
  display: grid;     
  grid-template-columns: .2fr .7fr .7fr .7fr .9fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5";
  border-radius: 1rem;
  margin-bottom: 1rem;
}
 
.assing-vehicle-modal .list-header p {
  font-size: 1.6rem;
  color: white;
  text-align: left;
  font-family: rbold;
}
.assing-vehicle-modal .list-header :nth-child(1) {
  grid-area: h1;
}
.assing-vehicle-modal .list-header :nth-child(2) {
  grid-area: h2;
}
.assing-vehicle-modal .list-header :nth-child(3) {
  grid-area: h3;
}
.assing-vehicle-modal .list-header :nth-child(4) {
  grid-area: h4;
}
.assing-vehicle-modal .list-header :nth-child(5) {
  grid-area: h5;
}

.assing-vehicle-modal .list-body > div {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  border: .1rem solid #DFE0EB;
  background-color: white;
  display: grid;
  grid-template-columns: .2fr .7fr .7fr .7fr .9fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5";
  border-radius: 1rem;
  margin-bottom: .5rem;
}

.assing-vehicle-modal .list-body p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
  margin-bottom: 0;
}
.assing-vehicle-modal .list-body > div :nth-child(1) {
  grid-area: b1;
}
.assing-vehicle-modal .list-body > div :nth-child(2) {
  grid-area: b2;
}
.assing-vehicle-modal .list-body > div :nth-child(3) {
  grid-area: b3;
}
.assing-vehicle-modal .list-body > div :nth-child(4) {
  grid-area: b4;
}
.assing-vehicle-modal .list-body > div :nth-child(5) {
  grid-area: b5;
}

.assing-vehicle-modal .footer button {
  height: 4rem;
  background-color: #AD0606;
  color: white;
  font-size: 1.6rem;
  border-radius: 5rem;
  padding: 0 2rem;
}
.assing-vehicle-modal .footer button:hover {
  background-color: #3751ff;
}
