.add-user-main p {
    font-size: 2rem;
    padding-left: 5rem;
    padding-top: 2rem;
    color: #D01400;
    font-weight: 700;
}

.add-user-main .normal-input {
    width: 45rem;
    border: .1rem solid #DFE0EB;
    height: 4rem;
    padding-left: 1rem;
    outline: none;
    border-radius: .8rem;
}

.add-user-main .input-item {
    max-width: 100%;
    width: 45rem;
}

.add-user-main .suggestion {
    top: 7rem;
}

.add-user-div1 {
    border: .1rem solid #DFE0EB;
    border-radius: 2rem;
    background-color: rgb(223, 224, 235, 12%);
    font-size: 1.6rem;
}

.add-input-container label {
    font-weight: 600;
}

.person-add-user {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    padding: .5rem;
    border: .1rem solid #b8b8bd;
}

.personfile {
    width: 10rem;
    height: 10rem;
    border-radius: 5rem;
    opacity: 1%;
}

.sub2-subsub1-sub1-add-user-div1 :nth-child(3) {
    bottom: 1.25rem;
    right: 2rem;
}

.sub2-subsub1-sub1-add-user-div1 input[type="checkbox"] {
    -webkit-appearance: none;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 5rem;
    cursor: pointer;
}

.sub2-subsub1-sub1-add-user-div1 input[type="checkbox"]:after {
    font-family: "Font Awesome 6 Free";
    content: "\f00c";
    font-size: 1rem;
    color: white;
    display: none;
    font-weight: 900;
}

.sub2-subsub1-sub1-add-user-div1 input[type="checkbox"]:checked {
    background-color: #1E6B97;
}

.sub2-subsub1-sub1-add-user-div1 input[type="checkbox"]:checked::after {
    display: block;
}

.subsub5-sub1-add-user-div1 button {
    width: 24rem;
    height: 4rem;
    background-color: #AD0606;
    color: white;
    font-size: 1.6rem;
    border-radius: 5rem;
    border: 0rem;
    margin-top: 3rem;
}
.subsub5-sub1-add-user-div1 button:hover {
    background-color: #3751ff;
}
.subsub5-sub1-add-user-div1 button:active {
    background-color: #640404;
}

@media(max-width:768px) {
    .add-user-main {
        padding: 2rem 1rem;
        overflow-y: auto;
        background-color: #F3F4F7;
    }

    .add-user-main p {
        text-align: center;
        font-size: 3rem;
        padding-left: 0;
    }

    .add-user-div1 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 1rem !important;
        border: none;
        background-color: inherit;
    }

    .add-input-container label {
        font-weight: 500;
        font-size: 2rem;
    }

    .add-user-main .normal-input {
        width: 100%;
    }


}