.add-company-main p {
    font-size: 2rem;
    padding-left: 5rem;
    padding-top: 2rem;
    color:#D01400;
    font-weight: 700;
}

.add-company-main .normal-input {
    width: 45rem;
    border: .1rem solid #DFE0EB;
    height: 4rem;
    padding-left: 1rem;
    outline: none;
    border-radius: .8rem;
}

.add-company-main .input-item {
    max-width: 100%;
    width: 45rem;
}

.add-company-div1 {
    border: .1rem solid #DFE0EB;
    border-radius: 2rem;
    background-color: rgb(223,224,235,12%);
    font-size: 1.6rem;
}

.person-add-company {
    /* width: 10rem; */
    height: 10rem;
}

.sub2-subsub1-sub1-add-company-div1 :nth-child(3) {
    bottom: 1.25rem;
    right: 2rem;
}

.sub2-subsub1-sub1-add-company-div1 input[type="checkbox"] {
    appearance: none;
    -webkit-appearance:none ;
    height: 1.5rem;
    border-radius: 5rem;
    cursor: pointer;
}

.sub2-subsub1-sub1-add-company-div1 input[type="checkbox"]:after {
    font-family: "Font Awesome 6 Free";
    content: "\f00c";
    font-size: 1rem;
    color:white;
    display: none;
    font-weight: 900;
}

.sub2-subsub1-sub1-add-company-div1 input[type="checkbox"]:checked {
    background-color: #1E6B97;
}

.sub2-subsub1-sub1-add-company-div1 input[type="checkbox"]:checked::after {
    display: block;
}

@media(max-width:768px) {
    .add-company-main {
        padding: 2rem 1rem;
    }

    .add-company-main p {
        text-align: center;
        font-size: 3rem;
        padding-left: 0;
    }

    .add-company-div1 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 1rem !important;
    }

    .add-input-container {
        width: 100%;
        flex-direction: column;
        margin-bottom: 1rem !important;
    }
    
    .add-input-container label {
        font-weight: 500;
        font-size: 2rem;
    }

    .add-company-main .normal-input {
        width: 100%;
    }
}
