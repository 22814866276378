.panel-eye-sensor .sensor-settings .input-element {
    width: 20rem;
}
.panel-eye-sensor .sensor-settings .input-name-element {
    margin-bottom: 0 !important;
}
.panel-eye-sensor .sensor-settings .input-name-element .text-input-container {
    width: 15rem;
}

.panel-eye-sensor .subtitle {
    font-family: rregular;
    font-size: 1.2rem;
    color: #FD2A22;
}
.panel-eye-sensor .subtitle .line {
    /* width: 21rem; */
    height: .1rem;
    background-color: #D01400;
}

.panel-eye-sensor .sensor-item .subtitle {
    padding-right: 6rem;
}

.panel-eye-sensor .sensor-mac-setting .text-input-container {
    width: 100% !important;
}