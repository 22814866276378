
.input-element {
  width: 29.1rem;
  height: 3.3rem;
  border: .1rem solid #898A8D;
  font-size: 1.2rem;
  font-family: rregular;
  border-radius: .5rem;
  color: #898A8D;
}

.sub1-subsub2-sub1-subsub2-sub1-subsub1-sub1-input-output-div3 {
  display: flex;
  _width: 10.6rem;
  height: 1.8rem;
  border: .1rem solid #898A8D;
  border-radius: .5rem;
  margin-left: 2rem;
  position: relative;
}

.sub1-subsub2-sub1-subsub2-sub1-subsub1-sub1-input-output-div3 :nth-child(1) {
  _width: 7.5rem;
  height: 100%;
  outline: none;
  text-align: center;
  font-size: 1.2rem;
  font-family: rregular;
  border: 0;
  border-radius: .5rem;
  _padding-left: 3.5rem;
  padding-right: .5rem;
}

.sub1-subsub2-sub1-subsub2-sub1-subsub1-sub1-input-output-div3 :nth-child(2) {
  position: absolute;
  right: 1rem;
  width: 2rem;
  height: 1.4rem;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.din-label, .din-value {
  font-size: 1.2rem;
  font-family: rregular;
}
.din-shape {
  width: 14px;
  height: 14px;
  margin-left: 4.5rem;
  margin-right: 1rem;
}
.din-shape.High {
  background-color: #28A745;
}
.din-shape.Low {
  background-color: #D01400;
}