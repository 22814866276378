.subsub1-sub1-company-div2 {
  height: 4.3rem;
  background-color: #364153;
  display: grid;
  grid-template-columns: .7fr 1fr 1fr 1.3fr 1fr .8fr 1.2fr .6fr .3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5 h6 h7 h8 h9";
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.subsub1-sub1-company-div2 p {
  font-size: 1.6rem;
  color: white;
  font-family: rbold;
}


.import-company {
  display: flex;
  margin-top: .5rem;
}

.sub1-import-company {
  background-color: white;
  width: 2.7rem;
  height: 2.7rem;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 2.4rem;
  border-top: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
}

.subsub1-sub2-company-div2 {
  width: 100%;
  height: 7.6rem;
  border: .1rem solid #DFE0EB;
  border-radius: 1rem;
  background-color: white;
  display: grid;
  grid-template-columns: .7fr 1fr 1fr 1.3fr 1fr .8fr 1.2fr .6fr .3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b6 b7 b8 b9";
}

.subsub1-sub2-company-div2 .item {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subsub1-sub2-company-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
}


.company-dropdown {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.company-dropdown-img {
  height: 1.6rem;
  cursor: pointer;
}

.company-dropdown-div {
  width: 17rem;
  border-radius: 1rem !important;
  border: .1rem solid rgb(122, 125, 139, 50%);
  box-shadow: 0 2px 7px 0px #aaa;
}

.company-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
}

@media(max-width:767px) {
  .company-main {
    padding: 5rem 1rem 0 1rem;
  }

  .subsub1-sub2-company-div2 {
    height: inherit;
    background-color: #364153;
    border-radius: 1rem;
  }

  .company-main .sub2-div2 {
    height: calc(100dvh - 38rem) !important;
  }
}