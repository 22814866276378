.sidebar {
  height: 100dvh;
}

.sidebar .logo {
  height: 7.2rem;
}

.logo p {
  color: red;
  font-size: 2.4rem;
  font-family: rbold;
}

.logo img {
  width: 6.5rem;
}

.main-sidebar {
  width: 30rem;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  background-color: #F7F8FC;
}

.main-sidebar .connected {
  border-radius: 1.5rem;
  cursor: pointer;
  width: 7rem;
}

.main-sidebar .connected.selected {
  background-color: rgba(122, 125, 139, 0.25);
}

.main-sidebar .connected p {
  font-size: 1.4rem;
  font-family: rregular;
}

.sub1-div5 p {
  background-color: #364153;
  font-size: 1.2rem;
  border: .2rem solid white;
  border-radius: .5rem;
  box-shadow: .1rem .1rem 1rem grey;
  font-family: rmedium;
}

.subsub1-sub2-div5 {
  height: 3rem;
  border-radius: .5rem;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

.subsub1-sub2-div5 img {
  left: 1rem;
  z-index: 1;
}

.subsub1-sub2-div5 .ant-select-selector {
  height: 2.5rem;
  border-radius: .5rem;
  font-size: 1.2rem;
  border: .1rem solid #a9aaac !important;
  background-color: #F2F3F6 !important;
  outline: none;
  font-family: rregular;
  margin-top: .2rem;
}
.subsub1-sub2-div5 .ant-select-selector .ant-select-selection-search input {
  text-align: center;
}
.subsub1-sub2-div5 .ant-select-clear {
  background-color: #f2f3f6 !important;
}

.filter-input {
  height: 3rem;
  border-radius: .5rem;
  border: .1rem solid rgb(0, 0, 0, 30%);
  background-color: #F2F3F6;
  /* color: rgb(0, 0, 0, 60%); */
  font-size: 1.2rem;
  outline: none;
  font-family: rregular;
}

.filter-input::placeholder,
.subsub1-sub2-div5 select::placeholder {
  color: rgb(0, 0, 0, 60%);
}

.sub1-div6 {
  height: 8rem;
  border-radius: .7rem;
  border: 1px solid #00000059;
  box-shadow: .1rem .1rem .8rem rgb(190, 189, 189);
  cursor: pointer;
}

.sub1-div6.selected {
  border: .1rem solid red;
  background-color: #00000033;
}

.left-sub1-div6 {
  width: 35%;
}

.left-sub1-div6 img {
  width: 6rem;
}

.left-sub1-div6 span {
  height: 2rem;
  line-height: 2rem;
  font-size: 1.2rem;
  border-radius: .5rem;
  font-family: rmedium;
}

.right-sub1-div6 {
  width: 65%;
}

.right-sub1-div6 span {
  font-size: 1.4rem;
  font-family: rbold;
}

.right-sub1-div6-sub1 img {
  width: 2.2rem;
}

.right-sub1-div6-sub1 p {
  font-size: 1.4rem;
  margin-left: .5rem;
  font-family: rregular;
}

.right-sub1-div6-sub2 {
  height: 2rem;
}
.right-sub1-div6-sub2 > img:hover {
  height: 2.2rem;
  /* animation: flipHorizontal .5s ; */
}
@keyframes flipHorizontal {
  50% {
      transform: rotateY(180deg);
  }
}

.running-bars {
  width: .8rem;
  margin-right: -0.3rem;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.running-bars > div {
  height: .1rem;
  margin-top: .08rem;
  width: 100%;
  background-color: #f89823;
}
.running-bar1 {
  animation: barWidthAnim1 0.25s 0.75s both infinite alternate;
}
.running-bar2 {
  animation: barWidthAnim2 0.5s 0.5s both infinite alternate;
}
.running-bar3 {
  animation: barWidthAnim1 0.5s both infinite alternate;
}
.running-bar4 {
  animation: barWidthAnim2 0.75s 0.5s both infinite alternate;
}
.running-bar5 {
  animation: barWidthAnim1 0.5s 0.25s both infinite alternate;
}
.vehicle-info-wrapper .ant-popover-inner {
  background: linear-gradient(180deg, #DFE0EB 0%, #7E7F85 100%);
}
.ant-popover.vehicle-info-wrapper .ant-popover-arrow:before {
  background: #DFE0EB;
}
.vehicle-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
}
.vehicle-info > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: .3rem;
}

@keyframes barWidthAnim1 {
  from { width: 30%; }
  to { width: 100%; }
}
@keyframes barWidthAnim2 {
  from { width: 30%; }
  to { width: 100%; }
}



.device-container {
  height: calc(100dvh - 36.5rem);
  scrollbar-width: none;
}

/* car portal */
.main-car-portal-side-bar {
  z-index: 999;
  top: 15rem;
  left: 31rem;
}

.car-close-portal-side-bar {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: -1rem;
  right: -1rem;
  cursor: pointer;
  z-index: 4;
}

.car-portal-side-bar {
  _width: 50rem;
  height: 47rem;
  border-radius: 2rem;
  box-shadow: -.2rem .6rem .5rem grey;
  z-index: 3;
}

.sub1-car-portal-side-bar {
  _width: 32.5%;
  width: 22rem;
  height: 100%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.subsub1-sub1-car-portal-side-bar img {
  width: 5rem;
  height: 5rem;
}

.subsub1-sub1-car-portal-side-bar p {
  font-size: 2rem;
  font-family: rbold;
}

.subsub2-sub1-car-portal-side-bar {
  background-color: #D9D9D9;
}

.subsub2-sub1-car-portal-side-bar p {
  font-size: 1.2rem;
  font-family: rbold;
}

.subsub3-sub1-car-portal-side-bar {
  background-color: #D9D9D9;
}

.sub1-subsub3-sub1-car-portal-side-bar img {
  width: 3rem;
}

.sub1-subsub3-sub1-car-portal-side-bar p {
  font-family: rmedium;
  font-size: 1.4rem;
}
.sub1-subsub3-sub1-car-portal-side-bar p span {
  font-size: 1rem;
}

.sub2-subsub3-sub1-car-portal-side-bar p {
  font-size: 1.2rem;
  font-family: rregular;
}

.subsub5-sub1-car-portal-side-bar {
  width: 100%;
  height: 19rem;
  background-color: rgb(196, 184, 170);
}

.subsub5-sub1-car-portal-side-bar img {
  width: 100%;
  height: 100%;
}

.subsub6-sub1-car-portal-side-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subsub6-sub1-car-portal-side-bar p {
  font-family: rbold;
  opacity: 0.5;
  font-size: 1.2rem;
}

.sub2-car-portal-side-bar {
  _width: 73.5%;
  width: 50rem;
  background-color: rgb(0, 0, 0, 94%);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.sub1-subsub1-sub2-car-portal-side-bar img {
  width: 5rem;
  height: 4.3rem;
}

.sub1-subsub1-sub2-car-portal-side-bar p {
  font-size: 1.2rem;
  font-family: rregular;
}

.sub2-car-portal-side-bar .horizontal-bar {
  border-color: grey;
}

.engine-status {
  font-family: rbold;
  font-style: italic;
  font-size: 1.2rem;
  color: #28A745;
}
.engine-status.OFF {
  color: #D01400;
}

.car-switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2.2rem;
}

.car-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.car-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CFD8DC;
  transition: 0.4s;
  border-radius: 5rem;
}

.car-slider .car-on-off {
  width: 95%;
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  border-radius: 5rem;
  margin-top: .1rem;
}

.car-slider .car-p {
  width: 1.1rem;
  height: .8rem;
  margin-top: .2rem;
  color: #455A64;
  margin-right: .4rem;
  font-weight: 500;
  font-family: rbold;
  font-size: 1rem;
}

.car-slider:before {
  position: absolute;
  content: "";
  height: 1.7rem;
  width: 1.7rem;
  right: 2.1rem;
  bottom: .3rem;
  background-color: #F44336;
  transition: 0.4s;
  border-radius: 5rem;
}
.car-switch input +.car-slider .car-p-on {
  visibility: hidden;
}
.car-switch input +.car-slider .car-p-off {
  visibility: visible;
}
.car-switch input:checked+.car-slider {
  background-color: #CFD8DC;
  border-radius: 5rem;
}
.car-switch input:checked+.car-slider::before {
  transform: translateX(1.8rem);
  background-color: #4CAF50;
}
.car-switch input:checked+.car-slider .car-p-on {
  visibility: visible;
}
.car-switch input:checked+.car-slider .car-p-off {
  visibility: hidden;
}

.sub1-subsub3-sub2-car-portal-side-bar label {
  width: 4rem;
  height: 2.2rem;
}

.wifi {
  font-size: 6rem;
  width: 8rem;
  height: 2.4rem;
}

.car-logo {
  width: 5.7rem;
  height: 2.4rem;
  margin-top: 1rem;
}

.truck-logo {
  width: 5.1rem;
  height: 2.9rem;
  margin-left: 5.3rem;
  margin-top: .7rem;
}

@media(max-width:1390px){
  .device-container {
    height: 77rem;
  }
}

@media(max-width:1255px) {
  html {
    font-size: 55%;
  }
}

@media(max-width:1200px) {
  html {
    font-size: 50%;
  }
}

@media(max-width:1080px) {
  html {
    font-size: 45%;
  }
}

@media(max-width:998px) {
  html {
    font-size: 40%;
  }
}

@media(max-width:768px) {
  .menu-icon {
    background-color: rgb(0, 0, 0, 0.78);
    height: 2.9rem;
    cursor: pointer;
    z-index: 9;
  }

  .down-arrow {
    top: 0;
  }

  .up-arrow {
    bottom: 0;
  }

  .up-arrow img {
    transform: rotate(180deg);
  }

  .main-sidebar {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1.5rem 1rem;
    z-index: 5;
  }

  .main-sidebar .filter {
    margin-top: 0 !important;
  }
  
  .main-sidebar .connected {
    width: 9rem;
  }
  
  .main-sidebar .connected p {
    font-size: 1.6rem;
  }
  
  .sub1-div5 p {
    font-size: 1.4rem;
  }
  
  .subsub1-sub2-div5 {
    height: 2.8rem;
  }
  
  .subsub1-sub2-div5 select {
    height: 2.8rem;
    font-size: 1.4rem;
  }
  
  .filter-input {
    height: 2.8rem;
    font-size: 1.4rem;
  }
  
  .sub1-div6 {
    height: 11rem;
    border-radius: .7rem;
    box-shadow: .1rem .1rem .8rem rgb(190, 189, 189);
    cursor: pointer;
  }

  .left-sub1-div6 img {
    width: 7rem;
  }
  
  .left-sub1-div6 span {
    height: 2.4rem;
    font-size: 1.6rem;
  }
  
  .right-sub1-div6 span {
    font-size: 1.6rem;
  }
  
  .right-sub1-div6-sub1 img {
    width: 2.4rem;
  }
  
  .right-sub1-div6-sub1 p {
    font-size: 1.6rem;
  }
  
  .right-sub1-div6-sub2 {
    height: 2.4rem;
  }
  
  .device-container {
    height: calc(100dvh - 37.5rem);
    scrollbar-width: none;
  }

  .main-car-portal-side-bar {
    left: 0;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .car-portal-side-bar {
    width: 100%;
  }

  .sub1-car-portal-side-bar {
    width: 40%;
  }

  .sub2-car-portal-side-bar {
    width: 60%;
  }

  .sub1-subsub1-sub2-car-portal-side-bar img {
    width: 4rem;
    height: 3rem;
  }
}
