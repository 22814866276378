.devices-main{
  width: 100%;
}

.devices-div1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sub1-div1 {
  width: 100%;
  height: 6.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  padding-left: 4rem;
  padding-top: 1rem;
}

.sub1-div1 p {
  font-size: 1.7rem;
  border: 0.2rem solid white;
  border-radius: 0.5rem;
  background-color: #364153;
  color: white;
  box-shadow: 0.1rem 0.5rem 1.1rem grey;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: rbold;
}

.sub1-div1 p span {
  font-size: 3rem;
  font-family: rbold;
}

.subsub1-sub1-div1 {
  display: flex;
}

.tab-button {
  min-width: 14rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  background-color: #ad0606;
  border-radius: 4rem;
  max-height: 4rem;
  cursor: pointer;
}
.tab-button.btn-success {
  background-color: #28A745;
}
.tab-button:hover {
  background-color: #3751ff;
}
.tab-button:active {
  background-color: #640404;
}

.tab-button img {
  width: 2rem;
  height: 2rem;
}

.tab-button button {
  font-size: 1.6rem;
  color: white;
  background-color: transparent;
}

.sub2-div1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 4rem;
  padding-right: 2rem;
  margin-bottom: 1rem;
}

.subsub1-sub2-div1 {
  background-color: white;
  border: 0.1rem solid #DFE0EB;
  width: 100%;
  height: 9.1rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
}

.sub1-subsub1-sub2-div1 {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-top: 0.5rem;
}

.search-icon-content {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}

.sub1-subsub1-sub2-div1 span {
  font-size: 1.6rem;
  color: #F22222;
  font-family: rbold;
  padding-left: 1rem;
}

.sub2-subsub1-sub2-div1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 1rem;
  gap: 1rem;
}

.sub2-subsub1-sub2-div1 .field-input {
  min-width: 10rem;
  width: 100%;
  height: 2.8rem;
  font-size: 1.6rem;
  color: black;
  text-align: center;
  border: 0.1rem solid #DFE0EB;
  border-radius: 0.5rem;
  outline: none;
  font-family: rregular;
}

.sub2-subsub1-sub2-div1 .field-select {
  min-width: 10rem;
  width: 100%;
  height: 2.8rem;
  font-size: 1.6rem;
  color: grey;
  text-align: center;
  border: 0.1rem solid #DFE0EB;
  border-radius: 0.5rem;
  outline: none;
  font-family: rregular;
}
.sub2-subsub1-sub2-div1 .field-select.ant-select .ant-select-selector {
  max-height: 100%;
  border: 0
}
.sub2-subsub1-sub2-div1 .field-select.ant-select .ant-select-selection-item {
  font-size: 1.6rem;
  line-height: 2.5rem;
}

.div2 {
  width: 100%;
  padding-top: 1rem;
}

.subsub1-sub1-devices-div2 {
  height: 4.4rem;
  background-color: #364153;
  display: grid;     
  grid-template-columns: .7fr .7fr .7fr .9fr 1fr .6fr .4fr .15fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5 h6 h7 h8 h9";
  border-radius: 1rem;
  margin-bottom: 1rem;
}
 
.subsub1-sub1-devices-div2 p {
  font-size: 1.6rem;
  color: white;
  text-align: left;
  font-family: rbold;
}

.sub2-div2 {
  height: calc(100dvh - 35rem);
  width: calc(100% + 2.7rem);
  /* scrollbar-width: none; */
  margin-left: -2.7rem;
}

#import-devices {
  display: flex;
  margin-top: .5rem;
}

#sub1-import-devices {
  width: 2.7rem;
  height: 2.7rem;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1.3rem;
  border-top: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
  background-color: white;
}

.subsub1-sub2-devices-div2 {
  width: 100%;
  height: 5.2rem;
  border: .1rem solid #DFE0EB;
  background-color: white;
  display: grid;
  grid-template-columns: .7fr .7fr .7fr .9fr 1fr .6fr .4fr .15fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b6 b7 b8 h9";
  border-radius: 1rem;
}

.subsub1-sub2-devices-div2 .item {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subsub1-sub2-devices-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
}

.subsub1-sub2-devices-div2 :nth-child(5) {
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
}

.import-gps-time-devices {
  width: 100%;
  line-height: 1.2rem;
  padding-top: 1rem;
}

.subsub1-sub2-devices-div2 :nth-child(8) {
  justify-content: center;
}

.devices-dropdown {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.devices-dropdown-img {
  height: 1.6rem;
  cursor: pointer;
}

.devices-dropdown-div{
  width: 12.9rem;
  height: 9.4rem;
  border-radius: 1rem !important;
  border: .1rem solid rgb(122,125,139, 50%);
  box-shadow: 0 2px 7px 0px #aaa;
}

.subsub1-sub1-devices-dropdown-div img {
  width: 1.5rem;
  height: 2rem;
}

.subsub1-sub1-devices-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
}

.action-item {
  padding: .2rem .5rem;
}
.action-item:hover {
  background-color: #f0f0f0;
}
.action-item:active {
  background-color: #dddddd;
}

@media(max-width:1390px) {
  .sub2-div2 {
    height: 72rem;
  }
}

@media(max-width:768px) {
  .subsub1-sub2-div1 {
    height: inherit;
  }

  .accordion {
    width: 1.5rem;
  }

  .sub1-div1 {
    height: inherit;
  }

  .sub1-div1 p {
    border-radius: 1rem;
  }

  .sub1-div1 p span {
    font-size: 2.5rem;
  }

  .tab-button {
    width: inherit;
    padding: .25rem .8rem;
  }

  .sub2-subsub1-sub2-div1 .field-input {
    height: 3.2rem;
  }

  .sub2-subsub1-sub2-div1 .field-select {
    height: 3.2rem;
  }

  .devices-main {
    padding: 5rem 1rem 0 1rem;
  }

  .subsub1-sub2-devices-div2 {
    height: inherit;
    background-color: #364153;
    border-radius: 1rem;
  }

  .devices-dropdown-div {
    height: 12rem;
  }

  .subsub1-sub2-devices-div2 .item {
    align-items: flex-start;
  }

  .devices-main .sub2-div2 {
    height: calc(100dvh - 38rem) !important;
  }

  .right-layout .sub2-div2.folded {
    height: calc(100dvh - 22rem) !important;
  }
}


.add-device-main .sensors {
  height: 36rem;
  overflow: auto;
}
