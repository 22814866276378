.subsub1-sub1-users-div2 {
  height: 4.4rem;
  background-color: #364153;
  display: grid;
  grid-template-columns: .7fr .7fr .7fr 1.2fr 1fr .8fr .8fr 1.4fr .5fr .3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5 h6 h7 h8 h9 h10";
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.subsub1-sub1-users-div2 p {
  font-size: 1.6rem;
  color: white;
  font-family: rbold;
}

#import-users {
  display: flex;
  margin-top: .5rem;
}

#sub1-import-users {
  width: 2.7rem;
  height: 2.7rem;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 2.4rem;
  border-top: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
  background-color: white;
}

.subsub1-sub2-users-div2 {
  width: 100%;
  height: 7.6rem;
  border: .1rem solid #DFE0EB;
  background-color: white;
  display: grid;
  grid-template-columns: .7fr .7fr .7fr 1.2fr 1fr .8fr .8fr 1.4fr .5fr .3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b6 b7 b8 b9 b10";
  border-radius: 1rem;
}

.subsub1-sub2-users-div2 .item {
  _width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  /* padding-left: 3rem; */
}

.subsub1-sub2-users-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
}

.login-status {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.login-status.on {
  background-color: #28A745;
}

.login-status.off {
  background-color: #D01400;
}

.person {
  width: 6rem !important;
  height: 6rem !important;
}

.subsub1-sub2-users-div2> :nth-child(1) {
  padding-left: 1rem;
}
.subsub1-sub2-users-div2 :nth-child(10) {
  justify-content: center;
}

.users-dropdown {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.users-dropdown-img {
  height: 1.6rem;
  cursor: pointer;
}

.users-dropdown-div {
  width: 16.5rem;
  _height: 12.9rem;
  border-radius: 1rem !important;
  border: .1rem solid rgb(122, 125, 139, 50%);
  box-shadow: 0 2px 7px 0px #aaa;
}

.sub1-users-dropdown-div img {
  width: 1.5rem;
  height: 1.5rem;
}

.sub1-users-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
}

.user-remove {
  position: absolute;
  width: 40rem;
  height: 20rem;
  z-index: 100;

  background-color: white;
  top: 40rem;
  left: 80rem;
  box-shadow: 1rem 1rem 2rem grey;
  border-radius: 1rem;
}

.user-remove h1 {
  text-align: center;
  padding-top: 7.5rem;
  font-family: rbold;
  color: #7A7D8B;
}

@media(max-width:767px) {
  .users-main {
    padding: 5rem 1rem 0 1rem;
  }

  .subsub1-sub2-users-div2 {
    height: inherit;
    background-color: #364153;
    border-radius: 1rem;
  }

  .users-main .sub2-div2 {
    height: calc(100dvh - 38rem) !important;
  }
}