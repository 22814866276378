.commands-queue-div1 {
  border: 0.1rem solid #dfe0eb;
  border-radius: 2rem;
  background-color: #F3F4F7;
  padding-top: 1rem;
  position: relative;
}

.sub1-commands-queue-div1 {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  padding-right: 2rem;
}

.sub1-commands-queue-div1 p {
  font-size: 1.2rem;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
  width: 15.9rem;
  height: 3.4rem;
  background-color: #28A745;
  color: white;
  box-shadow: 0.1rem 0.1rem 0.2rem grey;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: rmedium;
  cursor: pointer;
  margin-bottom: 0;
  margin-right: 1rem;
}

.commands-queue-div2 {
  padding: 1rem 2rem;
}

.sub1-commands-queue-div2 {
  width: 100%;
  _height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subsub1-sub1-commands-queue-div2 {
  background-color: #364153;
  width: 100%;
  height: 4.3rem;
  display: grid;
  grid-template-columns: 1fr .8fr .6fr .6fr .4fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5 h6";
  border-radius: 1rem;
  padding-top: .9rem;
  _margin-bottom: 1rem;
}

.subsub1-sub1-commands-queue-div2 p {
  font-size: 1.6rem;
  color: white;
  font-family: rbold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* //////////////////////////////////////////////////////////// */
.sub2-commands-queue-div2 {
  width: 100%;
  height: calc(100vh - 42rem);
  overflow: auto;
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subsub1-sub2-commands-queue-div2 {
  width: 100%;
  height: 5.3rem;
  border: .1rem solid #DFE0EB;
  margin-top: .2rem;
  margin-bottom: .2rem;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr .8fr .6fr .6fr .4fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b6";
  border-radius: .8rem;
}

.subsub1-sub2-commands-queue-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  height: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  padding-right: 1.5rem;
}

.subsub1-sub2-commands-queue-div2 :nth-child(1) {
  word-break: break-all;
}

.subsub1-sub2-commands-queue-div2 :nth-child(5) {
  font-size: 1.4rem;
}

.subsub1-sub2-commands-queue-div2 :nth-child(6) {
  word-break: break-all;
}

/* ////////////////////// */

/* Portal */
.commands-queue-portal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-20rem);
}
.commands-queue-portal {
  width: 37.8rem;
  height: 8.3rem;
  background-color: #364153;
  border-radius: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  padding: 0 1.5rem;
}

.commands-queue-portal label {
  position: absolute;
  left: 1.5rem;
  top: .8rem;
  color: #898A8D;
}

.commands-queue-portal-close {
  position: absolute;
  z-index: 1;
  left: 37rem;
  bottom: 7.3rem;
  width: 1.5rem;
  cursor: pointer;
  height: 1.5rem;
}

@media(max-width:768px) {
.sub2-commands-queue-div2 {
  height: calc(100vh - 34rem);
}
}