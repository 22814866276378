.sub1-system-params-div3 {
  /* width: 158.9rem; */
  height: calc(100vh - 300px);
  border: 0.1rem solid #dfe0eb;
  border-radius: 2rem;
  background-color: #F3F4F7;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 1rem;
  overflow: auto;
}

.getvers .text-input-container {
  border-style: dashed;
}

.command-setting-main .btn-group .btn-check + label {
  color: #7A7D8BB8;
  background: #D9D9D994;
  border: 0;
  font-size: 1.2rem;
}
.command-setting-main .btn-group .btn-check:checked + label {
  color: #fff;
  background: linear-gradient(95.25deg, #FD2A22 1.42%, #FE6C57 98.14%);
  box-shadow: 0px 4px 4px 0px #FD251E40, 0px 4px 4px 0px #00000040 inset;
}


.ignition-source .text-input-tooltip {
  right: -40px !important;
}




@media(max-width:768px) {
  .sub1-system-params-div3 {
    height: calc(100vh - 200px);
  }
}