.ticket-main .field-input input {
  border: none;
  text-align: center;
}

.all-ticket-div1 :nth-child(2) {
  margin-left: 3.6rem;
  background-color: #D01400;
}

.all-ticket-div1 :nth-child(3) {
  margin-left: 3.6rem;
  background-color: #28A745;
}

.subsub1-sub1-ticket-div2 {
  height: 4.3rem;
  background-color: #364153;
  display: grid;
  grid-template-columns: .3fr 1fr 1.5fr 1.3fr 1.3fr 1.3fr .8fr .3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5 h6 h7 h8";
  border-radius: 1rem;
  margin-bottom: 1rem;
}
 
.subsub1-sub1-ticket-div2 p {
  font-size: 1.6rem;
  color: white;
  font-family: rbold;
}

#import-ticket {
  display: flex;
  margin-top: .5rem;
}

#sub1-import-ticket {
  background-color: white;
  width: 2.7rem;
  height: 2.7rem;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1.3rem;
  border-top: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
}

.subsub1-sub2-ticket-div2 {
  width: 100%;
  height: 5.2rem;
  border: .1rem solid #DFE0EB;
  border-radius: 1rem;
  background-color: white;
  display: grid;
  grid-template-columns: .3fr 1fr 1.5fr 1.3fr 1.3fr 1.3fr .8fr .3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b6 b7 b8";
}

.subsub1-sub2-ticket-div2 .item {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subsub1-sub2-ticket-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
}

.ticket-dropdown {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.ticket-dropdown-img {
  height: 1.6rem;
  cursor: pointer;
}

.ticket-dropdown-div {
  width: 12.5rem;
  border-radius: 1rem !important;
  border: .1rem solid rgb(122, 125, 139, 50%);
  box-shadow: 0 2px 7px 0px #aaa;
}

.sub1-ticket-dropdown-div img {
  width: 1.5rem;
  height: 1.5rem;
}

.sub1-ticket-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
}

@media(max-width:767px) {
  .ticket-main {
    padding: 5rem 1rem 0 1rem;
  }

  .subsub1-sub2-ticket-div2 {
    height: inherit;
    background-color: #364153;
    border-radius: 1rem;
  }

  .ticket-main .sub2-div2 {
    height: calc(100dvh - 38rem) !important;
  }
}
