.command-panel {
  
  .panel-title {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 11px;
    color: #000;
    margin-bottom: 0;
  }

  .panel-description {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    margin-bottom: 0;
  }

  .panel-date {
    background: #D01400;
    height: 1.4rem;
    font-family: 'Roboto';
    color: #FFFFFF;
    padding: 0 1.5rem;
  }

  .panel-date.loadings {
    background: #ffc107;
    justify-content: center;
    color: #000;
  }

  .panel-content {
    position: relative;
  }

  .panel-content.loadings:before {
    content: '';
    background: #00000060;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }

  img.expand-collapse {
    transition: all .5s;
  }

  img.expand-collapse.expanded {
    transform: rotate(180deg);
  }
}