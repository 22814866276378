.map-control-button {
  width: 40px;
  height: 40px;
  padding: 10px;
  font-size: 20px;
  background: white;
  margin-right: 10px;
  cursor: pointer;
  color: #666;
}
.map-control-button:hover {
  color: black;
}

.vehicle-marker {
  cursor: default;
  transform: translateY(50%);
}
.mapboxgl-map .vehicle-marker {
  transform: none;
}

.vehicle-marker .marker-img {
  /* border: .2rem solid transparent; */
  border-radius: 1.5rem;
  /* width: 4rem; */
  height: 7rem;
}

.vehicle-marker .marker-info {
  position: absolute;
  top: 7.5rem;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #fff;
  font-size: 1.4rem;
  font-family: rregular;
  background-color: red;
  border: .1rem solid white;
  border-radius: .5rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: .6rem .8rem;
  min-width: 8rem;
  line-height: 1.8rem;
  z-index: 2;
}

.vehicle-marker .marker-info + .arrow {
  position: absolute;
  top: 6.9rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

/* .vehicle-marker.selected .marker-img {
  border-color: red;
} */
.vehicle-marker.connected .marker-info {
  background-color: #28A745;
} 
.vehicle-marker.notconnected .marker-info {
  background-color: red;
}
.vehicle-marker.idle .marker-info {
  background-color: #28A745;
}

.vehicle-marker .marker-info.reversed {
  top: auto;
  bottom: 7.5rem;
}
.vehicle-marker .marker-info.reversed + .arrow {
  transform: rotate(180deg) translateX(50%);
  top: auto;
  bottom: 6.9rem;
}


.vehicle-marker .marker-stopped-info {
  display: none;
  position: absolute;
  bottom: 6.5rem;
  left: 3rem;
  color: #F89823;
  font-size: 1.4rem;
  font-family: rregular;
  background-color: #000000;
  border: .1rem solid white;
  border-radius: .5rem;
  min-width: 13rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: .6rem .8rem;
  line-height: 1.8rem;
  text-wrap: nowrap;
}
.vehicle-marker:hover .marker-stopped-info {
  display: block;
}
.vehicle-marker .marker-stopped-info .arrow {
  position: absolute;
  bottom: -0.6em;
  left: -0.3rem;
  transform: rotate(270deg);
}

.vehicle-marker .marker-info.reversed + img + .marker-stopped-info {
  top: 7.2rem;
  bottom: auto;
}
.vehicle-marker .marker-info.reversed + img + .marker-stopped-info .arrow {
  position: absolute;
  top: -0.2em;
  bottom: auto;
  left: -0.7rem;
  transform: none;
}